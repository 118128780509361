.notifications-container {
  position: fixed;
  left: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: column-reverse;
  gap: 20px;
  width: 280px;
  height: auto;

  @media (min-width: 768px) {
    width: 320px;
  }
}

.notification {
  width: 100%;
  border-radius: 8px;
  background: #fff;
  box-shadow:
    0px 4px 6px -2px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  padding: 12px 24px 12px 12px;
  position: relative;

  .content {
    display: flex;
    align-items: start;
    gap: 10px;

    > div {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .icon {
      font-size: 20px;
    }

    .title {
      font-size: 14px;
      font-weight: 400;
      margin: 0;
    }
    .message {
      font-size: 14px;
      margin: 0;
    }
  }

  .close-btn {
    background: none;
    border: none;
    cursor: pointer;
    color: #9ca3af;
    line-height: 1;
    padding: 0;
    font-size: 0;
    position: absolute;
    top: 5px;
    right: 5px;
    appearance: none;

    span {
      font-size: 16px;
    }

    &:hover {
      color: #6b7280;
    }
  }

  &.success {
    .content {
      .title,
      .icon {
        color: #065f46;
      }
    }
  }

  &.error {
    .content {
      .title,
      .icon {
        color: #ef4444;
      }
    }
  }
}
