.store-cards {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 0;
  margin: 80px auto 0;
  padding: 40px 0;
  max-width: 1180px;

  > .plan {
    width: calc(100% / 3);
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    border-radius: 16px;
    background: $white;
    border-width: 1px;
    border-style: solid;
    border-color: #f7e5bc;

    .plan-header {
      display: flex;
      gap: 12px;
      flex-wrap: wrap;
      align-items: center;
      margin: 0 0 12px;
    }

    .name {
      margin: 0;
      font-size: 24px;
    }

    .enterprise-desc,
    .description {
      margin: 0 0 24px;
      font-size: 16px;
      min-height: 72px;
    }

    .price {
      margin: 0 0 32px;
      font-size: 36px;
      font-weight: 500;
      color: $primaryColor;
      line-height: 1;
      display: flex;
      align-items: baseline;
      gap: 4px;

      .label {
        font-size: 16px;
        font-weight: 300;
      }
    }

    .features {
      list-style: none;
      padding: 0;
      margin: 0 0 48px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      li {
        display: flex;
        align-items: center;
        gap: 12px;

        .icon {
          font-size: 20px;
          color: $primaryAccent;
        }

        p {
          margin: 0;
          font-size: 16px;
          max-width: calc(100% - 32px);
          color: $primaryColor;
        }
      }
    }

    .green-label {
      position: absolute;
      top: 20px;
      right: 0;
      padding: 8px 12px 8px 24px;
      font-size: 12px;
      background: #007704;
      line-height: 1;
      color: #fff;
      font-weight: 600;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    &.pro {
      margin-top: -40px;
      margin-bottom: -40px;
      margin-left: -1px;
      margin-right: -1px;
      box-shadow: 0 6px 32px $primaryAccent10;
      padding-top: 80px;
      padding-bottom: 40px;
      position: relative;
      z-index: 1;
      background: linear-gradient(180deg, rgba(253, 249, 237, .4), rgba(253, 249, 237, 0.1) 200px);
    }

    &:nth-child(1) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:nth-child(3) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.plan-icon {
  display: flex;
  width: 48px;
  height: 48px;
  margin: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &.plan-icon-free {
    background-image: url("../images/plans/plan-free.svg");
  }

  &.plan-icon-basic {
    background-image: url("../images/plans/plan-basic.svg");
  }

  &.plan-icon-advanced {
    background-image: url("../images/plans/plan-advanced.svg");
  }

  &.plan-icon-pro {
    background-image: url("../images/plans/plan-pro.svg");
  }

  &.plan-icon-enterprise {
    background-image: url("../images/plans/plan-enterprise.svg");
  }
}
