.filters-row {
  display: flex;
  max-width: 520px;
  width: 100%;
  align-items: center;
  gap: 20px;
  margin: 40px 0 80px;

  > div {
    &:first-child {
      width: calc(100% - 180px);
    }

    &:last-child {
      width: 160px;
    }
  }
}

.generated-content {
  list-style: none;
  padding: 0;
  margin: 40px 0 0;

  li,
  p,
  div {
    color: $primaryColor;
  }

  > li {
    padding: 0 0 40px;
    margin: 0 0 40px;
    border-bottom: 1px solid $borderColor;

    > ul {
      margin: 32px 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 10px;
      list-style: none;

      li {
        font-size: 14px;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 8px;

        > span {
          width: calc(100% - 16px);
        }

        &::before {
          content: "";
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: $primaryAccent;
          margin-top: 6px;
        }
      }
    }

    p {
      font-size: 16px;
      margin: 0;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 8px;
      // .icon {
      //     font-size: 18px;
      //     color: #ECC25A;
      // }
      // span {
      //     &:last-child {
      //         max-width: calc(100% - 26px);
      //     }
      // }
    }
  }

  .date-time {
    display: flex;
    align-items: center;
    gap: 20px;

    > div {
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 14px;
      line-height: 1;

      span {
        font-size: 18px;
        color: $primaryAccent;
      }
    }
  }
}

.events {
  .field-and-type-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0;
  }

  .range-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    flex-wrap: wrap;

    .range {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 24px;
    }
  }

  .chart-container,
  canvas {
    border-radius: $generalBorderRadius;
    border: 1px solid $borderColor;
    background: rgb(23 23 25 / 1%);
    margin: 40px 0 0;
    max-width: 1208px;
    max-height: 600px;

    .recharts-default-legend {

      li {
        display: inline-flex !important;
        align-items: center;
        justify-content: center;
        gap: 4px;
        margin-right: 24px !important;

        &:last-child {
          margin-left: 0 !important;
        }
      }
    }
  }

  .no-records {
    //border-radius: $generalBorderRadius;
    //border: 1px solid $borderColor;
    //margin: 40px 0 0;
    height: 604px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }

  .section-loader {
    height: 600px;
  }

  //.loading-wrapper {
  //  //border-radius: $generalBorderRadius;
  //  //border: 1px solid $borderColor;
  //  //margin: 40px 0 0;
  //  height: 604px;
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //
  //  .loader {
  //    width: 48px;
  //    height: 48px;
  //    border-top: 3px solid $primaryAccentShadow;
  //    border-left: 3px solid $primaryAccentShadow;
  //    border-right: 3px solid $primaryAccentShadow;
  //    border-bottom: 3px solid $primaryAccent;
  //    border-radius: 50%;
  //    -webkit-animation: loader-animation 1s infinite linear;
  //    animation: loader-animation 1s infinite linear;
  //  }
  //}
}

.pagination {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  flex-wrap: wrap;
  align-items: center;

  span {
    font-size: 16px;
    color: $primaryColor;
  }

  button {
    width: 32px;
    height: 32px;
    padding: 4px;

    span {
      color: $primaryAccent;
    }
  }
}