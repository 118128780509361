.auth-container {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 80px);
  .auth-spacer {
    margin: 20px 0;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    color: $secondaryColor;
    font-size: 18px;
    &::before,
    &::after {
      content: "";
      width: calc(50% - 30px);
      height: 1px;
      background: $secondaryColor;
      opacity: 0.3;
    }
  }
  form {
    margin: 50px 0 70px;
  }
  .form-header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    h1,
    p {
      margin: 0;
    }
    margin: 0 0 20px;
  }
}
