.installation-steps {
  h2 {
    margin: 0 0 20px;
  }

  ol {
    padding-left: 20px;
    margin: 40px 0 0;
    text-align: left;

    li {
      // font-size: 18px;
      margin: 0 0 16px;

      &:last-child {
        margin: 0;
      }

      code {
        display: flex;
        font-family: "Roboto Mono", monospace;
        font-size: 14px;
        padding: 10px 20px;
        position: relative;
        width: 100%;
        background-color: $white;
        border: 1px solid $borderColor;
        font-weight: 400;
        border-radius: $generalBorderRadius;
        color: $primaryColor;
        transition: 0.2s ease;
        appearance: none;
        outline: none;
        margin: 8px 0 0;
        overflow: auto;
        word-break: break-all;
      }
    }
  }

  form {
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
    z-index: -999;
  }

  .primary-button {
    min-width: 180px;
  }

  .copy-button {
    position: absolute;
    top: 50%;
    right: 4px;
    width: 32px;
    height: 32px;
    padding: 0;
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
    font-size: 20px;
    transform: translateY(-50%);
    cursor: pointer;
    transition: 0.2s ease;

    &.disabled {
      cursor: default;
    }
  }

  @media (max-width: 640px) {
    ol {
      li {
        font-size: 16px;
      }
    }
  }
}
