.form,
form {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .form-header {
    text-align: center;
  }

  fieldset {
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
  }

  .show-password {
    position: absolute;
    right: 10px;
    top: 30px;
    padding: 5px;
    cursor: pointer;
    color: $primaryColor;
    transition: 0.2s ease;

    &:hover {
      opacity: 0.6;
    }
  }

  .secondary-button,
  .primary-button {
    margin: 20px 0 0;
  }

  > .link {
    margin: 0 auto;
  }

  .error {
    font-size: 12px;
    padding-left: 21px;
    color: $errorRed;
  }
}

.form-field {
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;

  label {
    font-size: 12px;
    padding: 0 21px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  textarea,
  select,
  input[type="date"],
  input[type="text"],
  input[type="number"],
  input[type="email"],
  input[type="password"] {
    padding: 10px 20px;
    width: 100%;
    background-color: $white;
    border: 1px solid $borderColor;
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    border-radius: $generalBorderRadius;
    color: $primaryColor;
    transition: 0.2s ease;
    appearance: none;
    outline: none;

    img {
      height: 28px;
      width: auto;
    }

    &:focus,
    &:hover {
      border-color: $primaryAccent;
    }

    &:focus {
      box-shadow: $generalBoxShadowValues $primaryAccentShadow;
    }

    &::placeholder {
      color: $primaryColor;
      opacity: 0.3;
    }

    &.has-error {
      border-color: $errorRed;

      &:hover {
        border-color: $errorRed;
      }

      &:hover {
        box-shadow: $generalBoxShadowValues $errorRed;
      }
    }
  }

  select {
    padding-right: 30px;
  }

  textarea {
    min-height: 180px;
  }
}

select {
  background-image: url("../images/select-arrow-icon.svg");
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: calc(100% - 14px) 50%;
}

.form-field-checkbox {
  display: flex;
  flex-direction: column;
  gap: 5px;

  label {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    gap: 10px;
    color: $primaryColor;
    user-select: none;

    &.group-label {
      font-size: 12px;
      padding: 0 21px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &:hover {
      .custom-checkbox {
        + .checkbox-indicator {
          border-color: $primaryAccent;
        }
      }
    }

    &.has-error {
      .custom-checkbox {
        + .checkbox-indicator {
          border-color: $errorRed;
        }
      }

      &:hover {
        .custom-checkbox {
          + .checkbox-indicator {
            border-color: $errorRed;
          }
        }
      }
    }

    .custom-checkbox {
      + .checkbox-indicator {
        height: 20px;
        width: 20px;
        border: 1px solid $borderColor;
        border-radius: 6px;
        position: relative;
        transition: 0.2s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $white;

        &:before {
          content: "done";
          @extend .icon;
          font-size: 20px;
          width: 20px;
          height: 20px;
          transition: 0.2s ease;
          opacity: 0;
          color: $white;
        }
      }

      &:checked + .checkbox-indicator {
        border-color: $primaryAccent;
        background: $primaryAccent;
      }

      &:checked + .checkbox-indicator:before {
        opacity: 1;
      }
    }
  }

  .error {
    padding-left: 30px;
    margin-top: 5px;
  }
}

.form-field-radio {
  display: flex;
  flex-direction: column;
  gap: 5px;

  label {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    gap: 10px;
    color: $primaryColor;
    user-select: none;

    &.group-label {
      font-size: 12px;
      padding: 0 21px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &:hover {
      .custom-radio {
        + .radio-indicator {
          border-color: $primaryAccent;
        }
      }
    }

    &.has-error {
      .custom-radio {
        + .radio-indicator {
          border-color: $errorRed;
        }
      }

      &:hover {
        .custom-radio {
          + .radio-indicator {
            border-color: $errorRed;
          }
        }
      }
    }

    .custom-radio {
      + .radio-indicator {
        height: 20px;
        width: 20px;
        border: 1px solid $borderColor;
        border-radius: 50%;
        position: relative;
        transition: 0.2s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $white;

        &:before {
          content: "";
          width: 8px;
          height: 8px;
          transition: 0.2s ease;
          opacity: 0;
          background: $white;
          border-radius: 50%;
        }
      }

      &:checked + .radio-indicator {
        border-color: $primaryAccent;
        background: $primaryAccent;
      }

      &:checked + .radio-indicator:before {
        opacity: 1;
      }
    }
  }

  .error {
    padding-left: 30px;
    margin-top: 5px;
  }
}
