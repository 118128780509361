.modal {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  padding: 20px;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(12px);
  .modal-content {
    padding: 40px;
    background-color: $white;
    width: 100%;
    max-width: 640px;
    border-radius: 20px;
    box-shadow: 0 10px 99px rgba(0, 0, 0, 0.16);
    position: relative;
    .modal-icon {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      line-height: 80px;
      font-size: 38px;
      background-color: $primaryAccent10;
      border: 1px solid $primaryAccent40;
      color: $primaryAccent;
      box-shadow: 0 0 6px $primaryAccentShadow;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
    }
    .close {
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
      font-size: 24px;
      transition: 0.2s ease;
      opacity: 0.3;
      color: $primaryColor;
      &:hover {
        opacity: 0.6;
      }
    }
    h2 {
      text-align: center;
      margin: 30px 0;
      font-size: 22px;
    }
    .modal-actions {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      button {
        max-width: 140px;
      }
    }
  }
}
