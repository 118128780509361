.sm-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 20px;
  width: 100%;
  background: $white;
  border: 1px solid $borderColor;
  font-size: 18px;
  border-radius: $generalBorderRadius;
  color: $primaryColor;
  transition: 0.2s ease;
  appearance: none;
  outline: none;
  cursor: pointer;
  font-weight: 400;
  position: relative;
  overflow: hidden;
  img {
    height: 28px;
    width: auto;
  }
  &:hover {
    border-color: $primaryAccent;
    box-shadow: $generalBoxShadowValues $primaryAccentShadow;
  }
  &:disabled,
  &.disabled {
    cursor: not-allowed;
    &:hover {
      border-color: $borderColor;
      box-shadow: none;
    }
  }
  &.loading {
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: $white;
      z-index: 1;
    }
    &::after {
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
      z-index: 2;
      border-top: 2px solid $primaryAccentShadow;
      border-left: 2px solid $primaryAccentShadow;
      border-right: 2px solid $primaryAccentShadow;
      border-bottom: 2px solid $primaryAccent;
      border-radius: 50%;
      -webkit-animation: loader-animation 1s infinite linear;
      animation: loader-animation 1s infinite linear;
    }
  }
}

.primary-button {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 30px;
  width: 100%;
  color: $white;
  border: 1px solid $primaryAccent;
  font-size: 18px;
  line-height: 28px;
  border-radius: $generalBorderRadius;
  background: $primaryAccent;
  transition: 0.2s ease;
  appearance: none;
  outline: none;
  cursor: pointer;
  font-weight: 400;
  position: relative;
  overflow: hidden;
  &:hover {
    border-color: $white;
    box-shadow: $generalBoxShadowValues $primaryAccentShadow;
  }
  &:disabled,
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    &:hover {
      border-color: $primaryAccent;
      box-shadow: none;
    }
  }
  &.loading {
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: $primaryAccent;
      z-index: 1;
    }
    &::after {
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
      z-index: 2;
      border-top: 2px solid $white40;
      border-left: 2px solid $white40;
      border-right: 2px solid $white40;
      border-bottom: 2px solid $white;
      border-radius: 50%;
      -webkit-animation: loader-animation 1s infinite linear;
      animation: loader-animation 1s infinite linear;
    }
  }
}

.secondary-button {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 30px;
  width: 100%;
  color: $primaryAccent;
  border: 1px solid $primaryAccent;
  font-size: 18px;
  line-height: 28px;
  border-radius: $generalBorderRadius;
  background: $white;
  transition: 0.2s ease;
  appearance: none;
  outline: none;
  cursor: pointer;
  font-weight: 400;
  position: relative;
  overflow: hidden;
  &:hover {
    background: $primaryAccentHoverBg;
    box-shadow: $generalBoxShadowValues $primaryAccentShadow;
  }
  &:disabled,
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      box-shadow: none;
    }
  }
  &.loading {
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: $primaryAccentHoverBg;
      z-index: 1;
    }
    &::after {
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
      z-index: 2;
      border-top: 2px solid $primaryAccent40;
      border-left: 2px solid $primaryAccent40;
      border-right: 2px solid $primaryAccent40;
      border-bottom: 2px solid $primaryAccent;
      border-radius: 50%;
      -webkit-animation: loader-animation 1s infinite linear;
      animation: loader-animation 1s infinite linear;
    }
  }
}

.red-button {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 30px;
  width: 100%;
  color: $errorRed;
  border: 1px solid $errorRed;
  font-size: 18px;
  line-height: 28px;
  border-radius: $generalBorderRadius;
  background: $white;
  transition: 0.2s ease;
  appearance: none;
  outline: none;
  cursor: pointer;
  font-weight: 400;
  position: relative;
  overflow: hidden;
  &:hover {
    background: $errorRed10;
    box-shadow: $generalBoxShadowValues $errorRed10;
  }
  &:disabled,
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      box-shadow: none;
    }
  }
  &.loading {
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: $errorRed10;
      z-index: 1;
    }
    &::after {
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
      z-index: 2;
      border-top: 2px solid $errorRed40;
      border-left: 2px solid $errorRed40;
      border-right: 2px solid $errorRed40;
      border-bottom: 2px solid $errorRed40;
      border-radius: 50%;
      -webkit-animation: loader-animation 1s infinite linear;
      animation: loader-animation 1s infinite linear;
    }
  }
}

@-webkit-keyframes loader-animation {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes loader-animation {
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
