@media (min-width: 1024px) and (max-width: 1440px) {
  body {
    padding: 20px;
  }
  .page-content {
    padding-left: 300px;
  }
  .sidebar {
    top: 20px;
    left: 20px;
    width: 280px;
    height: calc(100vh - 20px);
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .store-cards {
    > .plan {
      width: 50%;

      &.enterprise {
        width: 100%;
        margin: 80px 0 0;
      }
    }
  }
}

@media (min-width: 641px) and (max-width: 1023px) {
  .store-cards {
    > .plan {
      width: 50%;

      &.enterprise {
        width: 100%;
        margin: 80px 0 0;
      }
    }
  }
}

@media (max-width: 1023px) {
  body {
    padding: 20px;
  }
  .page-content {
    padding-left: 0;
  }
  .app-loader {
    min-height: calc(100vh - 40px);
  }
  .sidebar {
    top: 0;
    left: 0;
    transform: translateX(-120%);
    width: 280px;
    height: 100%;
    transition: .2s ease;
    z-index: 999;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    box-shadow: 20px 0 99px rgba(0, 0, 0, 0.1);
    padding-right: 20px;

    .logo {
      display: none;
    }

    .sidebar-content {
      padding: 20px;
    }

    &.open {
      transform: translateX(0);
    }

    .close-sidebar {
      display: flex;
    }
  }
  .mobile-sidebar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 18px;
      width: 32px;
      height: 32px;
      align-items: center;
      justify-content: center;
      color: $primaryAccent;
      background: $primaryAccent10;
      border-radius: 50%;
      border: 1px solid $primaryAccent;
    }

    img {
      margin: 0;
      height: 32px;
    }
  }
  .page-content .view {
    padding: 20px;
    border-radius: 20px;
  }
  .step {
    padding: 12px;
    border-radius: 12px;
  }
  .form-field {
    select,
    input[type=date],
    input[type=text],
    input[type=number],
    input[type=email],
    input[type=password] {
      font-size: 16px;
      line-height: 18px;
    }
  }
  .form-field-checkbox {
    label {
      font-size: 16px;
    }
  }
}

@media (min-width: 1024px) {
  .mobile-sidebar {
    display: none;
  }
  .sidebar-overlay.opened,
  .sidebar-overlay {
    display: none;
  }
}

@media (max-width: 640px) {
  .events {
    .form-field,
    .range-wrapper .range {
      width: 100%;
    }
  }
  .store-cards {
    gap: 40px;
    padding: 0;

    > .plan {
      width: 100%;
      border-radius: 16px !important;

      &.pro {
        //padding: 40px 20px;
        margin: 0;
      }
    }
  }
  .dashboard-tabs a {
    padding: 0 12px 10px;
    font-size: 14px;
  }
}
