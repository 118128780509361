.dashboard-tabs {
  display: flex;
  font-size: 0;
  line-height: 0;
  border-bottom: 1px solid $borderColor2;
  // margin: 0 -40px;
  // padding: 0 40px;
  a {
    padding: 0 20px 10px;
    font-size: 16px;
    line-height: 1.2;
    text-decoration: none;
    color: $primaryColor;
    margin-bottom: -1px;
    &.active {
      color: $primaryAccent;
      border-bottom: 1px solid $primaryAccent;
    }
  }
}
