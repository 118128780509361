.sidebar {
  width: 320px;
  background: #fff;
  height: calc(100vh - 80px);
  border-radius: 40px;
  box-shadow: -20px 10px 99px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 40px;
  left: 40px;

  .sidebar-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    //overflow: auto;
    height: 100%;
    gap: 120px;
    padding: 40px 20px;

    > div {
      display: flex;
      flex-direction: column;
    }
  }

  .close-sidebar {
    display: none;
    position: absolute;
    top: 24px;
    right: -10px;
    font-size: 18px;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    color: $white;
    background: $primaryAccent;
    border-radius: 50%;
    border: 1px solid $white;
  }

  .logo {
    height: 40px;
    margin: 0 auto 80px;
  }

  .nav-items {
    display: flex;
    flex-direction: column;
    gap: 10px;
    list-style: none;
    padding: 0;
    margin: 0;

    div {
      appearance: none;
      border: 1px solid transparent;
      background: transparent;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 7px 15px;
      font-size: 16px;
      width: 100%;
      border-radius: 20px;
      transition: 0.2s ease;
      cursor: pointer;
      color: $primaryColor;

      .icon {
        color: inherit;
        font-size: 18px;
      }

      &:hover,
      &.active {
        border-color: $primaryAccent;
        background: $primaryAccent10;
      }
    }
  }

  .user-details {
    margin: 0 0 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;

    .avatar {
      width: 80px;
      height: 80px;
      border: 1px solid $primaryAccent;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      box-shadow: 0 0 6px $primaryAccentShadow;
      background: $primaryAccent10;

      span {
        color: $primaryColor;
      }
    }

    p {
      margin: 0;
      font-size: 16px;
      color: $primaryColor;
      text-align: center;
    }
  }

  .sign-out {
    font-size: 14px;
    padding: 0 20px;
    cursor: pointer;
    color: $primaryColor;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    transition: 0.2s ease;

    .icon {
      color: $primaryColor;
      font-size: 18px;
    }

    &:hover {
      opacity: 0.6;
    }
  }
}

.mobile-sidebar {
  background: #fff;
  border-radius: 12px;
  box-shadow: -20px 10px 99px rgba(0, 0, 0, 0.05);
  padding: 4px;
  margin: 0 0 20px;
  position: relative;
  z-index: 2;
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  overflow: auto;
  padding: 20px;
  background: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  z-index: -999;
  display: none;

  &.visible {
    display: flex;
    z-index: 999;
  }
}