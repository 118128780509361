.fields-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 0;
  margin: 80px auto 40px;

  .field-item {
    padding: 10px 20px;
    width: 100%;
    background-color: $white;
    border: 1px solid $borderColor;
    border-radius: $generalBorderRadius;
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    color: $primaryColor;
    transition: 0.2s ease;
    appearance: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      height: 28px;
      width: auto;
    }

    &:hover {
      border-color: $primaryAccent;
      background: $primaryAccent10;
      box-shadow: $generalBoxShadowValues $primaryAccentShadow;

      .field-actions {
        opacity: 1;
      }
    }

    h3 {
      font-size: 18px;
      margin: 0;
    }

    span {
      font-size: 16px;
    }
  }

  .field-actions {
    display: flex;
    align-items: center;
    gap: 5px;
    opacity: 0;
    transition: 0.2s ease;

    span {
      cursor: pointer;
      width: 24px;
      height: 24px;
      font-size: 24px;
      transition: 0.2s ease;
      color: $primaryColor;

      &:hover {
        opacity: 0.4;
      }
    }
  }
}

.fields-editor {
  .form {
    .primary-button {
      margin-top: 0;
    }

    //.primary-button,
    //.secondary-button {
    //    width: 240px;
    //    margin-left: auto;
    //    margin-right: auto;
    //}
  }

  .conditions {
    flex-direction: row;
    gap: 20px;

    .slide-no {
      width: 100px;
    }

    .condition-value {
      width: 160px;
    }
  }

  .steps-nav-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .steps-nav {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 40px auto;
    padding: 0 20px;
    position: relative;

    > span {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $primaryAccent;
      color: $primaryAccent;
      background: $white;
      cursor: pointer;
      transition: 0.2s ease;
      position: relative;
      z-index: 2;

      &:hover {
        background: $primaryAccentHoverBg;
      }

      &.active {
        border: 1px solid $white;
        background: $primaryAccent;
        color: $white;
        cursor: default;
      }
    }

    &:before {
      content: "";
      position: absolute;
      z-index: 1;
      left: 0;
      width: 100%;
      height: 14px;
      top: 50%;
      transform: translateY(-50%);
      background: radial-gradient(circle, #fbf3de 3px, transparent 3px) 0 0 /
        14px 14px;
    }
  }

  .accent-link {
    margin-left: 20px;
    display: inline-flex;
    color: $primaryAccent;
    transition: 0.2s ease;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }

  .options-wrapper {
    padding-left: 20px;
    gap: 10px;

    label {
      margin-bottom: -5px;
    }
  }

  .option-field {
    display: flex;
    align-items: center;

    .accent-link {
      margin-left: 10px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
    margin: 20px 0 0;

    button {
      margin: 0;
      width: 150px;
    }
  }

  .slides-count {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 20px;
  }
}

.generated-text {
  margin: 0 0 20px;
}

.step {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border: 1px solid $borderColor;
  border-radius: $generalBorderRadius;
  position: relative;
  overflow: hidden;

  //&.step-settings {
  //    .step-header {
  //        padding-left: 40px;
  //        h3 {
  //            width: 100%;
  //        }
  //    }
  //}
  .step-header {
    display: flex;
    align-items: center;
    gap: 10px;

    h3 {
      margin: 0;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      width: 100%;

      > .header-text {
        display: flex;
        flex-direction: column;
        //gap: 4px;
        > span {
          font-size: 14px;
          color: $secondaryColor;
          font-weight: 400;
          opacity: 0.6;
        }
      }
    }
  }

  .step-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .icon-button {
    background: none;
    border: none;
    outline: none;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $primaryAccent;
    color: $primaryAccent;
    background: $white;
    cursor: pointer;
    transition: 0.2s ease;
    position: relative;
    z-index: 2;
    transition: 0.2s ease;

    &.opened {
      transform: rotate(45deg);
    }

    &:hover {
      background: $primaryAccentHoverBg;
    }

    &.active {
      border: 1px solid $white;
      background: $primaryAccent;
      color: $white;
      cursor: default;
    }
  }

  .step-actions {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .delete-button {
    opacity: 0;
    color: $errorRed;
    border: 1px solid $errorRed;
    margin-right: -34px;

    &:hover {
      background: $errorRed10;
    }
  }

  &.opened,
  &:hover {
    .delete-button {
      opacity: 1;
      margin-right: 0;
    }
  }

  .delete-confirmation {
    padding: 20px;
    border: 1px solid $errorRed30;
    border-radius: $generalBorderRadius;

    p {
      margin: 0 0 20px;
      color: $primaryColor;
      text-align: center;
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      button {
        margin: 0;
        width: auto;
        min-width: 100px;
        font-size: 16px;
        line-height: 18px;
      }
    }
  }
}

.add-new-card-button {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border: 1px solid $borderColor;
  border-radius: $generalBorderRadius;
  position: relative;
  appearance: none;
  background: $white;
  cursor: pointer;
  transition: 0.2s ease;
  justify-content: center;
  align-items: center;

  &:hover {
    background: $primaryAccentHoverBg;
    border: 1px solid $primaryAccent;
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.upgrade-message {
  padding: 20px;
  border-radius: $generalBorderRadius;
  border: 1px solid $primaryAccent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: $primaryAccent;
    color: $white;
    font-size: 24px
  }

  p {
    margin: 0;
    text-align: center;
    color: $primaryColor;
  }
}
