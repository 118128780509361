.page-content {
  padding-left: 360px;
  min-height: calc(100vh - 80px);

  .view-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 80px);
  }

  .view {
    width: 100%;
    min-height: calc(100vh - 80px);
    background: #fff;
    border-radius: 40px;
    padding: 40px;
    box-shadow: 20px 10px 99px rgba(0, 0, 0, 0.05);
  }
}

.profile-layout {
  padding: 40px 0 0;
  gap: 120px;
  display: flex;

  .title-section {
    h2,
    p {
      margin: 0 0 20px;
    }

    p {
      font-size: 16px;
    }
  }

  .description {
    width: 100%;
    max-width: 480px;
  }

  .actions {
    width: 100%;
    max-width: 520px;
  }
}

.full-width-layout {
  .title-section {
    text-align: center;

    h2 {
      margin: 0 0 20px;
    }

    p {
      font-size: 16px;
      margin: 0;
    }
  }
}

.center-layout {
  max-width: 520px;
  margin: 40px auto;

  .title-section {
    text-align: center;

    h2,
    p {
      margin: 0 0 20px;
    }

    p {
      font-size: 16px;
    }
  }
}
