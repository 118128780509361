@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  box-sizing: border-box;
}

html {
  margin: 0;
  padding: 0;
}

body {
  padding: 40px;
  margin: 0;
  background: $bodyBackground;
  font-family: $bodyFont;
  //font-weight: 300;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;

  b, strong {
    font-weight: 500;
  }
}

.logo {
  width: auto;
  height: 50px;
  margin: 0 auto;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 24px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $primaryColor;
  font-weight: 500;
}

p,
span,
label {
  color: $secondaryColor;
}

.link {
  transition: 0.2s ease;
  color: $linkColor;

  &:hover {
    text-decoration: none;
    opacity: 0.6;
  }
}

.copyrights {
  margin: 0;
  text-align: center;
}

.icon {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; // avoid content wrapping
}

.banner {
  margin: 0 0 40px;
  background: #fff;
  border-radius: 40px;
  padding: 24px 40px;
  box-shadow: -20px -10px 99px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  gap: 32px;
  justify-content: space-between;

  .icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    line-height: 80px;
    font-size: 38px;
    background-color: $primaryAccent10;
    color: $primaryAccent;
    border: 1px solid #fff;
    box-shadow: 0 0 6px $primaryAccentShadow;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > div {
    display: flex;
    gap: 24px;
    align-items: center;
  }

  p {
    margin: 0;
    color: $primaryColor;
    font-size: 18px;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.text-primary-accent {
  color: $primaryAccent;
}

.accent-link {
  display: inline-flex;
  color: $primaryAccent;
  transition: 0.2s ease;
  gap: 5px;
  align-items: center;

  .icon {
    color: inherit;
    font-size: inherit;
  }

  cursor: pointer;

  &.loading,
  &:hover {
    opacity: 0.6;
  }

  &.loading {
    cursor: not-allowed;
  }
}

.secondary-text {
  color: $secondaryColor;
}

.primary-text {
  color: $primaryColor;
}

.no-data-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 100px 0;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 1px solid $primaryAccent;
    color: $primaryAccent;
    font-size: 36px;
    background: $primaryAccent10;
    margin: 0 0 32px;
  }

  h2 {
    margin: 0;
  }

  p {
    font-size: 16px;
    margin: 12px 0 32px;
  }

  button {
    width: auto;
  }
}

.section-loader {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 320px;
  align-items: center;
  justify-content: center;
  gap: 8px;

  .loader {
    content: "";
    width: 48px;
    height: 48px;
    z-index: 2;
    border-top: 4px solid $primaryAccent40;
    border-left: 4px solid $primaryAccent40;
    border-right: 4px solid $primaryAccent40;
    border-bottom: 4px solid $primaryAccent;
    border-radius: 50%;
    -webkit-animation: loader-animation 1s infinite linear;
    animation: loader-animation 1s infinite linear;
  }

  p {
    font-size: 16px;
    color: $primaryColor
  }
}

.app-loader {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(100vh - 80px);
  align-items: center;
  justify-content: center;
  gap: 8px;

  .loader {
    content: "";
    width: 48px;
    height: 48px;
    z-index: 2;
    border-top: 4px solid $primaryAccent40;
    border-left: 4px solid $primaryAccent40;
    border-right: 4px solid $primaryAccent40;
    border-bottom: 4px solid $primaryAccent;
    border-radius: 50%;
    -webkit-animation: loader-animation 1s infinite linear;
    animation: loader-animation 1s infinite linear;
  }

  p {
    font-size: 16px;
    color: $primaryColor
  }
}
