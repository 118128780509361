$bodyBackground: #f7f7f8;
$bodyFont: "Poppins", sans-serif;
$primaryColor: #3A3A40;
$secondaryColor: #757682;
$linkColor: #466afc;
$borderColor: rgba(113, 112, 117, 0.3);
$borderColor2: rgba(113, 112, 117, 0.1);
$white: #fff;
$white40: rgba(255, 255, 255, 0.4);
//$primaryAccent: #ecc25a;
$primaryAccent: #F6C000;
$primaryAccent10: rgba(246, 192, 0, 0.1);
$primaryAccent20: rgba(246, 192, 0, 0.2);
$primaryAccent40: rgba(246, 192, 0, 0.4);
$primaryAccentShadow: rgba(236, 194, 0, 0.4);
$primaryAccentHoverBg: #fdf9ef;
$errorRed: #ff4040;
$errorRed10: #fff1f1;
$errorRed20: #ffdfdf;
$errorRed30: #ffc5c5;
$errorRed40: #ff9d9d;
$errorShadow: rgba(255, 64, 64, 0.2);
$generalBorderRadius: 20px;
$generalBoxShadowValues: 0 0 6px;
