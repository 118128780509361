.subscription-card {
  margin: 80px 0 0;
  padding: 40px;
  background-color: $white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: -10px 10px 80px rgba(0, 0, 0, 0.05);
  border-radius: $generalBorderRadius;
  position: relative;

  h3 {
    margin: 0 0 12px;
    font-size: 20px;
    display: flex;
    align-items: center;

    span {
      color: $primaryColor;
    }

    gap: 12px;

    .plan-icon {
      margin: 0;
      width: 32px;
      height: 32px;
    }
  }

  .subscription-date {
    font-size: 16px;
    margin: 0 0 32px;
  }

  .status {
    position: absolute;
    top: 10px;
    right: 0;
    padding: 8px 24px 8px 32px;
    font-size: 12px;
    background: #007704;
    line-height: 1;
    color: #fff;
    font-weight: 600;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;

    &.cancelled {
      background: #c60000;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;

    .accent-link {
      font-size: 16px;
    }

    > .primary-button,
    > .secondary-button {
      width: auto;
      padding: 10px 24px;
      font-size: 16px;
      min-width: 160px;
    }
  }

  .fills {
    font-size: 16px;
    margin: 20px 0 0;
  }

  button {
    margin: 24px 0 0;
  }
}
